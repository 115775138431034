'use client';

import { createContext } from 'react';

export type TGlobalContext = {
  config: any;
};

const GlobalContext = createContext<TGlobalContext>({
  config: null,
});

export default GlobalContext;
