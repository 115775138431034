import { FC } from 'react';

// -----------------------------------------------------------------------------
interface Props {
  name: string;
  startIndex: number;
  endIndex: number;
}

// -----------------------------------------------------------------------------
export const AutoCompleteText: FC<Props> = ({ name, startIndex, endIndex }) => {
  let result = '';

  for (let i = 0; i < name.length; i++) {
    if (i >= startIndex && i < endIndex) {
      result += `<b>${name[i]}</b>`;
    } else {
      result += name[i];
    }
  }

  return <span dangerouslySetInnerHTML={{ __html: result }} />;
};
