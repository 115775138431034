'use client';

import { useEffect, useState } from 'react';
import Utils from '../services/utils';

const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    setIsMobile(Utils.isMobile());
  }, []);

  return isMobile;
};

export default useIsMobile;
