'use client';

import { useContext, useMemo } from 'react';
import { useTranslation } from '../../../hooks/use-translation';
import { kImageFolderUrl } from '../../../services/constants';
import SpriteIcon from '../../shared/sprite-icon';
import { useBuildDownloadUrl } from '../../../hooks/use-download-url-builder';
import GlobalContext from '../../../context/global-context';

export const PageFooter = (): JSX.Element => {
  const { t } = useTranslation();

  const { config } = useContext(GlobalContext);
  const {
    baseUrl,
    curesforgeBase,
    tebexBase,
    nitroBase,
    curesforgeSupportBase,
    owIdeaBase,
    supportBaseUrl,
    cfStudiosBase } = config?.publicRuntimeConfig?.base;

  const {
    discord,
    reddit,
    facebook,
    linkedin,
    twitter } = config?.publicRuntimeConfig?.links.social;

  const { footerLinks, appstore } = config?.publicRuntimeConfig?.links;

  const downloadUrl = useBuildDownloadUrl({
    channel: 'web_dl_btn'
  });

  const fullYear = useMemo<string>(() => {
    return `${new Date().getFullYear()}`;
  }, []);

  const handleClickBackToTop = (): void => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className='site-footer'>
      <a
        href='#'
        className='back-to-top'
        onClick={handleClickBackToTop}
      >
        <SpriteIcon icon='big-arrow' />
        {t('backToTop')}
      </a>
      <img
        src={`${kImageFolderUrl}/footer-shield.webp`}
        width='261px'
        height='303px'
        className='ow-shield'
        alt='Overwolf'
      />

      <div className='site-footer-nav'>
        <SpriteIcon icon='ow-symbol' svgClassName='ow-symbol' />
        <SpriteIcon icon='overwolf-logo-and-text' svgClassName='ow-logo' />
        <nav>
          <ul>
            <li>
              <h5>{t('footer.creators')}</h5>
              <ul>
                <li>
                  <a href={`${footerLinks.buildAnApp}`}>
                    {t('footer.buildApp')}
                  </a>
                </li>
                <li>
                  <a href={`${curesforgeBase}`}>
                    {t('footer.publishMod')}
                  </a>
                </li>
                <li>
                  <a href={`${tebexBase}`}>
                    {t('footer.monetizeServer')}
                  </a>
                </li>
                <li>
                  <a href={`${nitroBase}`}>
                    {t('footer.monetizeWebsite')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${footerLinks.gettingStarted}`}
                  >
                    {t('footer.appDoc')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${footerLinks.modDocs}`}
                  >
                    {t('footer.modDoc')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${footerLinks.acceleration}`}
                  >
                    {t('footer.accelerator')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${footerLinks.fund}`}
                  >
                    {t('footer.applyFunding')}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <h5>{t('footer.gamers')}</h5>
              <ul>
                <li>
                  <a
                    className='list-item'
                    href={`${appstore}`}
                  >
                    {t('footer.appstore')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${curesforgeBase}`}
                  >
                    {t('footer.mods')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={downloadUrl}
                  >
                    {t('footer.downloadOverwolf')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${owIdeaBase}`}
                  >
                    {t('footer.ideas')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${curesforgeSupportBase}`}
                  >
                    {t('footer.helpCenter')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${discord}`}
                  >
                    {t('footer.discordCommunity')}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <h5>{t('footer.partners')}</h5>
              <ul>
                <li>
                  <a
                    className='list-item'
                    href={`${cfStudiosBase}`}
                  >
                    {t('footer.gameStudios')}
                  </a>
                </li>
                <li>
                  <a href={`${footerLinks.ads}`}>
                    {t('footer.advertise')}
                  </a>
                </li>
                <li>
                  <a href={`${footerLinks.adsAudience}`}>
                    {t('footer.audiences')}
                  </a>
                </li>
                <li>
                  <a href={`${footerLinks.adsSolutions}`}>
                    {t('footer.adSolutions')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${footerLinks.influencers}`}
                  >
                    {t('footer.influencers')}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <h5>{t('footer.company')}</h5>
              <ul>
                <li>
                  <a
                    className='list-item'
                    href={`${footerLinks.about}`}
                  >
                    {t('footer.aboutUs')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${footerLinks.culture}`}
                  >
                    {t('footer.ourCulture')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${baseUrl}/brand-guidelines/`}
                  >
                    {t('footer.brandGuidlines')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={footerLinks.blog}
                  >
                    {t('footer.blog')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${footerLinks.careers}`}
                  >
                    {t('footer.careers')}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <h5>{t('footer.support')}</h5>
              <ul>
                <li>
                  <a
                    className='list-item' href={`${supportBaseUrl}`}
                  >
                    {t('footer.helpCenter')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${footerLinks.supportedGames}`}
                  >
                    {t('supportedGames')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${footerLinks.terms}`}
                  >
                    {t('footer.terms')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${footerLinks.privacy}`}
                  >
                    {t('footer.privacy')}
                  </a>
                </li>
                <li>
                  <a
                    className='list-item'
                    href={`${footerLinks.licenses}`}
                  >
                    {t('footer.licenses')}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>

      <div className='site-footer-social'>
        <div className='site-footer-social-inner'>
          <span className='copyright'>
            {t('footer.trademark', { fullYear })}
          </span>
          <ul className='social-links'>
            <li>
              <a
                href={`${discord}`}
                title={t('footer.discord')}
                target='_blank'
                rel='noopener noreferrer'
              >
                <SpriteIcon icon='Discord' />
              </a>
            </li>
            <li>
              <a
                href={`${reddit}`}
                title={t('footer.reddit')}
                target='_blank'
                rel='noopener noreferrer'
              >
                <SpriteIcon icon='Reddit' />
              </a>
            </li>
            <li>
              <a
                href={`${facebook}`}
                title={t('footer.facebook')}
                target='_blank'
                rel='noopener noreferrer'
              >
                <SpriteIcon icon='Facebook' />
              </a>
            </li>
            <li>
              <a
                href={`${linkedin}`}
                title={t('footer.linkedIn')}
                target='_blank'
                rel='noopener noreferrer'
              >
                <SpriteIcon icon='LinkedIn' />
              </a>
            </li>
            <li>
              <a
                href={`${twitter}`}
                title={t('footer.twitter')}
                target='_blank'
                rel='noopener noreferrer'
              >
                <SpriteIcon icon='Twitter' />
              </a>
            </li>
          </ul>
        </div>

      </div>
    </footer>
  );
};
