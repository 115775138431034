import { FC } from 'react';
import { kAssetSpriteUrl } from '../../services/constants';
import { useClassName } from '../../hooks/use-class-name';

interface Props {
  icon: string;
  svgClassName?: string;
}

const SpriteIcon: FC<Props> = props => {
  const { icon, svgClassName } = props;
  const className = useClassName({
    [svgClassName]: !!svgClassName
  });
  return (
    <svg className={className}>
      <use href={`${kAssetSpriteUrl}#${icon}`} />
    </svg>
  );
};

export default SpriteIcon;
