import { useContext, useState } from 'react';
import GlobalContext from '../../../context/global-context';
import { useTranslation } from '../../../hooks/use-translation';
import { MenuItem } from './menu-item';
import SpriteIcon from '../../shared/sprite-icon';

enum SubMenus {
  BrowseByGame = 1,
  BrowseByCategories = 2,
}

export const SubMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const { config } = useContext(GlobalContext);
  const { baseUrl } =config?.publicRuntimeConfig?.base;
  const browseByGame  = config?.publicRuntimeConfig?.links?.browseByGame;

  const [openItemId, setOpenItemId] = useState<number | null>(1);

  const handleSubMenuMobileItemClick = (id: number): void => {
    if (openItemId === id) {
      // Close the current item if it's already open
      setOpenItemId(null);
    } else {
      // Open the clicked item and close others
      setOpenItemId(id);
    }
  };

  return (
    <div className='sub-menu'>
      <div className='sub-menu-item-list'>
        <h2
          className={
            SubMenus.BrowseByGame === openItemId ? 'is-open' : ''
          }
          onClick={() => handleSubMenuMobileItemClick(SubMenus.BrowseByGame)}
        >
          {t('Browse by Game')}
          <SpriteIcon icon='caret' />
        </h2>
        <ul className='menu-column-list'>
          <MenuItem
            name='All Games'
            url={`${browseByGame}/view-all`}
            gameIconSrc='/static_next/img/games-icons/all-games.png'
          />
          <MenuItem
            name='League of Legends'
            url={`${browseByGame}/league-of-legends`}
            gameIconSrc='/static_next/img/games-icons/lol.png'
          />
          <MenuItem
            name='Valorant'
            url={`${browseByGame}/valorant`}
            gameIconSrc='/static_next/img/games-icons/valorant.png'
          />
          <MenuItem
            name='Fortnite'
            url={`${browseByGame}/fortnite`}
            gameIconSrc='/static_next/img/games-icons/fortnite.png'
          />
          <MenuItem
            name='Minecraft'
            url={`${browseByGame}/minecraft`}
            gameIconSrc='/static_next/img/games-icons/minecraft.png'
          />
          <MenuItem
            name='Counter-Strike 2'
            url={`${browseByGame}/counter-strike-2`}
            gameIconSrc='/static_next/img/games-icons/cs2.png'
          />
          <MenuItem
            name='Teamfight Tactics'
            url={`${browseByGame}/teamfight-tactics`}
            gameIconSrc='/static_next/img/games-icons/tft.png'
          />
          <MenuItem
            name='Rocket League'
            url={`${browseByGame}/rocket-league`}
            gameIconSrc='/static_next/img/games-icons/rocket-league.png'
          />
          <MenuItem
            name='Hearthstone'
            url={`${browseByGame}/hearthstone`}
            gameIconSrc='/static_next/img/games-icons/hearthstone.png'
          />
          <MenuItem
            name='Rainbow Six Siege'
            url={`${browseByGame}/rainbow-six-siege`}
            gameIconSrc='/static_next/img/games-icons/rainbow-six-siege.png'
          />
          <MenuItem
            name='World of Warcraft'
            url={`${browseByGame}/world-of-warcraft`}
            gameIconSrc='/static_next/img/games-icons/wow.png'
          />
        </ul>
      </div>

      <div className='sub-menu-item-list'>
        <h2
          className={
            SubMenus.BrowseByCategories === openItemId ? 'is-open' : ''
          }
          onClick={
            () => handleSubMenuMobileItemClick(SubMenus.BrowseByCategories)
          }
        >
          {t('browseByCategories')}
          <SpriteIcon icon='caret' />
        </h2>
        <ul className='menu-column-list'>
          <MenuItem
            name='Guides & Trainers'
            url={`${baseUrl}/apps/guides-trainers`}
          />
          <MenuItem
            name='Enemy Stats'
            url={`${baseUrl}/apps/enemy-stats`}
          />
          <MenuItem
            name='Performance Trackers'
            url={`${baseUrl}/apps/performance-trackers`}
          />
          <MenuItem
            name='Fun Stuff'
            url={`${baseUrl}/apps/fun-stuff`}
          />
          <MenuItem
            name='Capture & Stream'
            url={`${baseUrl}/apps/capture`}
          />
          <MenuItem
            name='Music'
            url={`${baseUrl}/apps/entertainment`}
          />
          <MenuItem
            name='Utilities'
            url={`${baseUrl}/apps/utilities`}
          />
          <MenuItem
            name='Communication'
            url={`${baseUrl}/apps/chat`}
          />
          <MenuItem
            name='Mods'
            url={`${baseUrl}/apps/mods-managers`}
          />
          <MenuItem
            name='Beta Apps'
            url={`${baseUrl}/apps/beta-apps`}
          />
          <MenuItem
            name='Social'
            url={`${baseUrl}/apps/social`}
          />
        </ul>
      </div>

    </div>
  );
};
