'use client';

import { useEffect, useState } from 'react';
import Utils from '../services/utils';

const useOS = (): string => {
  const [os, setOs] = useState<string>('');

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    setOs(Utils.getOS());
  }, []);

  return os;
};

export default useOS;
