import { useMemo } from 'react';

// -----------------------------------------------------------------------------
export const useClassName = (
  classesConditions: any,
  defaultClassname = '',
  deps = []
): string => {
  const className = useMemo<string>(() => {
    const classes = [];

    Object.keys(classesConditions).forEach(className => {
      const condition = classesConditions[className];
      if (condition) {
        classes.push(className);
      }
    });

    return `${defaultClassname} ${classes.join(' ')}`;
  }, [classesConditions, defaultClassname].concat(deps));

  return className;
};
