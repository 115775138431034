import { FC } from 'react';
import { OwAppTile } from '../../../../interfaces/domain/ow-app-tile.modal';
import { SearchAutoCompleteItem } from './search-auto-complete-item';

// -----------------------------------------------------------------------------
interface Props {
  searchTerm: string;
  searchResults?: OwAppTile[];
}

// -----------------------------------------------------------------------------
export const SearchAutoComplete: FC<Props> = ({
  searchResults,
  searchTerm,
}) => {
  return (
    <div className='autocomplete'>
      <ul className='autocomplete-list'>
        {searchResults?.map((item, index) => {
          return (
            <SearchAutoCompleteItem
              key={index}
              index={index}
              item={item}
              searchTerm={searchTerm}
            />
          );
        })}
      </ul>
    </div>
  );
};
