'use client';

import GlobalContext from './global-context';

interface Props {
  children: React.ReactNode;
  config: any;
}

const GlobalContextProvider = (props: Props): JSX.Element => {
  const { config, children } = props;
  return (
    <GlobalContext.Provider value={{ config }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
