import { FC } from 'react';
import SpriteIcon from '../../../shared/sprite-icon';
import { OwAppTile } from '../../../../interfaces/domain/ow-app-tile.modal';
import { AutoCompleteText } from './search-auto-complete-text';

// -----------------------------------------------------------------------------
interface Props {
  index: number;
  item: OwAppTile;
  searchTerm: string
}

// -----------------------------------------------------------------------------
export const SearchAutoCompleteItem: FC<Props> = ({
  index,
  item,
  searchTerm,
}) => {

  const startIndex = item.title.toLowerCase().indexOf(searchTerm);
  const endIndex = startIndex === -1 ? 0 : startIndex + searchTerm.length;

  return (
    <li key={index}>
      <a
        href={`/app/${item.seoName} `}
      >
        <SpriteIcon icon='search' />
        <AutoCompleteText
          name={item.title}
          startIndex={startIndex}
          endIndex={endIndex}
        />
      </a>
    </li>
  );
};
