import { LocalizationService } from '../services/localization-service';

interface UseTranslationHook {
  t: (key: string, params?: { [key: string]: string }) => string
}

export const useTranslation = (): UseTranslationHook => {
  const t = (
    key: string,
    params?: { [key: string]: string }
  ): string => {
    return LocalizationService.Translate(key, params);
  };

  return { t };
};
