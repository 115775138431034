import texts from '../locales/en.json';

export class LocalizationService {
  public static Translate(
    key: string,
    params?: { [key: string]: string }
  ): string {
    const template = LocalizationService.getText(key);
    if (!template) {
      console.error(`Missing key ${key}`);
      return key;
    }

    return LocalizationService.applyParams(template, params);
  }

  private static getText(key): string {
    let obj = { ...texts };
    for (let i = 0, path = key.split('.'), len = path.length; i < len; i++) {
      if (!path || !obj) {
        return key;
      }

      obj = obj[path[i]];
    }

    if (obj === undefined) {
      return key;
    }

    return `${obj}`;
  }

  private static applyParams(
    template: string,
    params?: { [key: string]: string }
  ): string {
    if (!params) {
      return template;
    }

    let res = template;
    Object.keys(params).forEach(key => {
      res = res.replaceAll(`[${key}]`, params[key]);
    });
    return res;
  }
}
