import { ITrackingService } from '../../interfaces/services/tracking-service.interface';

// -----------------------------------------------------------------------------
export class OWTrackingService implements ITrackingService {

  // ---------------------------------------------------------------------------
  public async track(name: string, extra?: any): Promise<void> {
    try {
      const params = new URLSearchParams();
      params.append('Name', name);
      params.append('Value', '0');
      if (!extra) {
        extra = {};
      }

      params.append('Extra', encodeURIComponent(`${JSON.stringify(extra)}`));

      const query = params.toString();
      const url = `https://analyticsnew.overwolf.com/analytics/Counter?${query}`;

      const xmlhttp = new XMLHttpRequest();
      xmlhttp.open('GET', url);
      xmlhttp.send();
    } catch (e) {
      console.error('sendAnalytic error', e);
    }
  }

}
