import { IgTagService } from '../../interfaces/services/gtag-service.interface';

// -----------------------------------------------------------------------------
export class GTagTrackingService implements IgTagService {
  private gtag: any;

  // ---------------------------------------------------------------------------
  constructor() {
    this.initialize();
  }

  // ---------------------------------------------------------------------------
  public gtagPush(
    value: object,
  ): void {
    
    this.gtag?.push(value);
  }

  // ---------------------------------------------------------------------------
  private initialize(): void {
    if (typeof window.dataLayer === undefined) {
      return;
    }

    this.gtag = window.dataLayer;
  }
}
