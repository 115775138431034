import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ISearchService } from '../interfaces/services/search-service.interface';
import GlobalContext from '../context/global-context';
import { ServicesHandler } from '../services/service-handler';
import { OwAppTile } from '../interfaces/domain/ow-app-tile.modal';
import { AppBusEventType } from '../interfaces/bus-events';
import { dispatch } from './use-bus';

// -----------------------------------------------------------------------------
interface UseSearchServiceHook {
  fetchSearchResults(searchTerm: string): Promise<void>;
  searchResults: OwAppTile[];
}

// -----------------------------------------------------------------------------
export const useSearchService = (): UseSearchServiceHook => {
  const service = useRef<ISearchService>(null);
  const { config } = useContext(GlobalContext);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const { remoteSearchService } = ServicesHandler.getInstance(config);
    service.current = remoteSearchService;
  }, []);

  const fetchSearchResults = useCallback(
    async (searchTerm: string): Promise<void> => {
      const res = await service.current.getSearchItems(searchTerm);
      setSearchResults(res);

      dispatch({
        type: AppBusEventType.App.SearchHeaderResult,
        data: { searchResults: res, searchTerm },
      });
    },
    []
  );

  return {
    fetchSearchResults,
    searchResults,
  };
};
