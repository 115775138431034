'use client';
import { useContext } from 'react';
import GlobalContext from '../../../context/global-context';
import { useTranslation } from '../../../hooks/use-translation';
import { kImageFolderUrl } from '../../../services/constants';

export const BigBanner = (): JSX.Element => {
  const { t } = useTranslation();
  const { config } = useContext(GlobalContext);
  const { baseUrl } = config?.publicRuntimeConfig?.base;

  return (
    <a
      href={`${baseUrl}/creators/build-an-app/`}
      target='_blank'
      rel='noreferrer'
      className='big-banner'
    >
      <img 
        className='desktop'
        src={`${kImageFolderUrl}/banners/web-banner.webp`}
        alt={t('footer.Build an app')}
      />
      <img
        className='mobile'
        src={`${kImageFolderUrl}/banners/mobile-banner.webp`}
        alt={t('footer.Build an app')}
      />
    </a>
  );
};
