import { useContext } from 'react';
import GlobalContext from '../context/global-context';

interface Param {
  channel: string;
  name?: string;
  extensionId?: string;
  utmContent?: string;
}
const sanitizeString = (value: string): string => {
  if (!value) {
    return;
  }

  return value.replaceAll(' ', '_');
};

export const useBuildDownloadUrl = (param: Param): string => {
  const { config } = useContext(GlobalContext);
  const downloadBaseUrl  = config?.publicRuntimeConfig?.base?.downloadBaseUrl;
  const downloadUrlPath = '/install/Download';
  let url = `${downloadBaseUrl}${downloadUrlPath}?Channel=${param.channel}`;

  if (param.name) {
    url = `${url}&Name=${sanitizeString(param.name)}`;
  }

  if (param.extensionId) {
    url = `${url}&ExtensionId=${param.extensionId}`;
  }

  if (param.utmContent) {
    url = `${url}&utm_content=${sanitizeString(param.utmContent)}`;
  }
  return url;
};
