import { FC, useState } from 'react';
import { SubMenu } from './sub-menu';
import SpriteIcon from '../../shared/sprite-icon';
import { useClassName } from '../../../hooks/use-class-name';

type MenuItemTypes = 'Button' | 'Link';
interface Props {
  url?: string;
  name: string;
  type?: MenuItemTypes;
  gameIconSrc?: string;
  hasSubMenu?: boolean;
}

export const MenuItem: FC<Props> = props => {
  const {
    name,
    url,
    gameIconSrc,
    hasSubMenu,
    type = 'Link',
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const className = useClassName({
    'has-sub-menu': hasSubMenu
  }, 'menu-item');

  const buttonClassName = useClassName({
    'is-open': isOpen
  });

  const handleMobileMaiMenuOpenClose = (): void => {
    setIsOpen(isOpen => !isOpen);
  };

  const MenuItemContent: FC = () => {
    return (
      <>
        {gameIconSrc &&
          <img src={gameIconSrc} alt={name} />
        }

        {name}

        {hasSubMenu &&
          <SpriteIcon icon='caret' />
        }
      </>
    );
  };

  return (
    <li className={className}>
      {type == 'Link' &&
        <a href={url}>
          <MenuItemContent />
        </a>
      }

      {type == 'Button' &&
        <button 
          className={buttonClassName}
          onClick={handleMobileMaiMenuOpenClose}
        >
          <MenuItemContent />
        </button>
      }

      {hasSubMenu &&
        <SubMenu />
      }
    </li>
  );
};