'use client';
import React, { FC, FormEvent, useState, KeyboardEvent, useEffect, useRef } from 'react';
import SpriteIcon from '../../../shared/sprite-icon';
import { SearchAutoComplete } from './search-auto-complete';
import { useSearchService } from '../../../../hooks/use-search';
import { usePathname } from 'next/navigation';
import { useTranslation } from '../../../../hooks/use-translation';
import useIsMobile from '../../../../hooks/use-is-mobile';

interface Props {
  url?: string;
}

// -----------------------------------------------------------------------------
export const HeaderSearch: FC<Props> = () => {
  const params = usePathname();
  const isSearchPage = params.includes('search');
  const { t } = useTranslation();
  const { fetchSearchResults, searchResults } = useSearchService();
  const [searchText, setSearchText] = useState<string>('');
  const searchInputElement = useRef<HTMLInputElement>(null);
  const isMobile = useIsMobile();
  
  const onSearchTextChange = async (value: string): Promise<void> => {
    void fetchSearchResults(value);

    setSearchText(value);
  };

  const clearSearch = (): void => {
    setSearchText('');
    searchInputElement.current.focus();
    void fetchSearchResults('');
  };

  const onKeyDown = (e: FormEvent<HTMLInputElement>): void => {
    const event = e as KeyboardEvent<HTMLInputElement>;

    if (event.key === 'Enter') {
      window.location.href = `/search?q=${searchText}`;
      return;
    }

    e.stopPropagation();
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const query = searchParams.get('q');
    if (isSearchPage && query) {
      setSearchText(query);
      void fetchSearchResults(query);
    } else {
      setSearchText('');
    }

    if (isMobile) {
      searchInputElement.current.focus();
    }

  },[]);

  return (
    <div className='appstore-search'>
      <a
        href='/search'
        className='clean-icon-btn static search-btn'
        title={t('search.title')}
      >
        <SpriteIcon icon='search' />
      </a>
      <div className='appstore-search-inner'>
        <button
          className='clean-icon-btn static back-btn'
          title={t('search.goBackBtn')}
          onClick={() => history.back()}
        >
          <SpriteIcon icon='arrow-left' />
        </button>
        <div className='search'>
          <SpriteIcon svgClassName='search-icon' icon='search' />
          <input
            type='search'
            onChange={e => onSearchTextChange(e.target.value)}
            placeholder={t('search.placeholder')}
            value={searchText}
            onKeyDown={e => onKeyDown(e)}
            ref={searchInputElement}
          />
          <button
            title={t('search.clearBtn')}
            className='clear-search clean-icon-btn'
            onClick={() => clearSearch()}
          >
            <SpriteIcon icon='clearSearch' />
          </button>
        </div>
        {!isSearchPage && (
          <SearchAutoComplete
            searchTerm={searchText}
            searchResults={searchResults}
          />
        )}
      </div>
    </div>
  );
};
