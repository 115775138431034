'use client';
import { useEffect, useRef} from 'react';
import { ITrackingService, mpObject } from '../interfaces/services/tracking-service.interface';
// import { ServicesHandler } from '../services/service-handler';
import { IgTagService } from '../interfaces/services/gtag-service.interface';
import { GTagTrackingService } from '../services/tracking/gtag-tracking.service';
import { OWTrackingService } from '../services/tracking/ow-tracking.service';

// -----------------------------------------------------------------------------
interface UseAnalyticsHook {
  track: (name: string, extra: mpObject) => void;
  gtagPush: (object: object) => void;
}

// -----------------------------------------------------------------------------
export const useAnalytics = (): UseAnalyticsHook => {
  const owTracking = useRef<ITrackingService>(null);
  const gtagTracking = useRef<IgTagService>(null);

  useEffect(() => {
    const trackingService = new OWTrackingService();
    owTracking.current = trackingService;

    const gtagService = new GTagTrackingService();
    gtagTracking.current = gtagService;
  }, []);

  const track = (name: string, extra: mpObject): void => {
    owTracking.current?.track(name, extra);
  };

  const gtagPush = (object: object): void => {
    gtagTracking.current?.gtagPush(object);
  };

  return {
    track,
    gtagPush,
  };
};
