'use client';

import { useContext, useState } from 'react';
import { useAnalytics } from '../../../hooks/use-analytics';
import { dispatch } from '../../../hooks/use-bus';
import { useBuildDownloadUrl } from '../../../hooks/use-download-url-builder';
import { useTranslation } from '../../../hooks/use-translation';
import { AppBusEventType } from '../../../interfaces/bus-events';
import Utils from '../../../services/utils';
import SpriteIcon from '../../shared/sprite-icon';
import { MenuItem } from './menu-item';
import GlobalContext from '../../../context/global-context';
import { OWLogo } from './logo';
import { useClassName } from '../../../hooks/use-class-name';
import { HeaderSearch } from './search/search-header';
import useOS from '../../../hooks/use-os';

export const PageHeader = (): JSX.Element => {
  const analytics = useAnalytics();
  const downloadUrl = useBuildDownloadUrl({
    channel: 'web_dl_btn',
    utmContent: 'new-light'
  });

  const { config } = useContext(GlobalContext);
  const { appstore }  = config?.publicRuntimeConfig?.links;
  const { baseUrl } = config?.publicRuntimeConfig?.base;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const OS = useOS();

  const { t } = useTranslation();

  const hamburgerMenuClassNames = useClassName({
    'is-open': isOpen
  }, 'hamburger-menu-button');

  const mobileMenuHeaderClassNames = useClassName({
    'is-open': isOpen
  }, 'header-inner');

  const handleMobileMenuOpenClose = (): void => {
    setIsOpen(isOpen => !isOpen);
  };

  const handleClickDownload = (event: React.MouseEvent<HTMLElement>): void => {
    Utils.InstallerDownloadOverwrite(event);
    analytics.track('appstore_download_clicked', {
      'appId': 'Clean client',
      'appName': 'Clean client',
      'component': 'App one page',
      'platform': 'Web',
    });

    analytics.gtagPush({
      event: 'app_download_cta',
      app_name: 'Clean client',
      app_id: 'Clean client',
      app_os: OS,
    });

    dispatch({
      type: AppBusEventType.App.ShowDownloadModal,
      data: { isOpen: true, currentApp: {appId: null, appName: null} }
    });
  };

  return (
    <header>
      <div className={mobileMenuHeaderClassNames}>
        <div className='left-side'>
          <button
            id='hamburger-menu-button' 
            className={hamburgerMenuClassNames}
            onClick={handleMobileMenuOpenClose}
          >
            <div>
              <span />
              <span />
              <span />
            </div>
          </button>

          <a href={`${appstore}/`} className='logo-area'>
            <span className='sr-only'>Overwolf Appstore</span>
            <OWLogo />
          </a>

          <nav className='navbar'>
            <ul className='menu-list'>
              <MenuItem
                name={t('browseApps')}
                type='Button'
                hasSubMenu={true}
              />
            </ul>
          </nav>
        </div>
        <HeaderSearch />

        <div className='right-side'>
          <a
            href={`${baseUrl}/creators/build-an-app/`}
            className='btn-secondary small icon cta-btn'
            target='_blank' rel='noreferrer'
          >
            {t('footer.buildApp')}
            <SpriteIcon icon='arrowRight' />
          </a>

          <a
            onClick={handleClickDownload}
            href={downloadUrl}
            className='btn-primary small dl-btn'
            download='OverwolfInstaller'
          >
            {t('downloadOverwolf')}
          </a>
        </div>
      </div>
    </header>

  );
};
